<template>
    <q-layout>
        <!-- TOP / BRAND -->
        <!-- navbar -->
        <navbar></navbar>

        <!----------------------->
        <!-- PAGE SUB HEADER -->
        <!-- #1 -->
        <div v-if="id == 1">
            <div class="bg-cover bg-nature-drop-water">
                <div class="container mx-auto">
                    <div class="p-5 row">
                        <div class="col-12 flex flex-col items-start">
                            <h1 class="text-white text-3xl font-black font-epigrafica mt-2 uppercase ml-2">SHEA BUTTER</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="container mx-auto">
                    <div class="py-14 bg-cover row">
                        <div class="container mx-auto">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                     <q-img fit="cover" class="img-smooth-zoom" ratio="1" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-karite.jpg" />
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-4xl font-black font-epigrafica mt-2 uppercase">MOISTURIZES AND NOURISHES IN DEPTH THE SKIN, BUT ALSO THE CAPILLARY FIBERS...</h1>
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-5">What is shea butter ?</h1>
                                    <p class="text-mineralgreen text-base">
                                        Shea butter comes from an African tree, the Butyrospermum parkii. It is a naturally fatty  almond present in the fruits and transformed which gives this product, whose many cosmetic virtues are not any more to prove.
                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-2">Its benefits</h1>
                                    <p class="text-mineralgreen text-base">
                                        Shea butter moisturizes and nourishes in depth the skin, but also the capillary fibres. It has a big advantage: it does not leave a greasy film when applied. Protector against UV, the shea butter is in particular essential in summer because it also alleviates the cutaneous irritations, due for example to the sunstrokes. It also prolongs the tanning  process and helps the skin to keep its elasticity. Shea butter demonstrates its benefits on  a daily basis, especially for hand, lip and hair care.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- #2 -->
        <div v-if="id == 2">
            <div class="bg-cover bg-nature-drop-water">
                <div class="container mx-auto">
                    <div class="p-5 row">
                        <div class="col-12 flex flex-col items-start">
                            <h1 class="text-white text-3xl font-black font-epigrafica mt-2 uppercase ml-2">HONEY</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="container mx-auto">
                    <div class="py-14 bg-cover row">
                        <div class="container mx-auto">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                     <q-img fit="cover" class="img-smooth-zoom" ratio="1" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-miel.jpg" />
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-4xl font-black font-epigrafica mt-2 uppercase">ABSORBS AND RETAINS MOISTURE AND LEAVES YOUR SKIN FRESH AND SUPPLE...</h1>
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-5">A care for your beautiful skin</h1>
                                    <p class="text-mineralgreen text-base">
                                        Honey can do real miracles on your skin (you can find it in many beauty products today). Its benefits are numerous: anti-aging, moisturizing, antioxidant, honey protects the skin from UV rays and facilitates its regeneration. Moreover, honey absorbs and retains moisture and leaves your skin fresh and supple.
                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-2">A care for your hair</h1>
                                    <p class="text-mineralgreen text-base">
                                        Honey is miraculous for damaged and dry hair and for hair that has lost its shine. It cleanses and shines.
                                    </p>
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-2">A solution against acne</h1>
                                    <p class="text-mineralgreen text-base">
                                        Acne is often caused by excess of sebum clogging the pores of the skin. Since honey absorbs small impurities from the skin's pores, it acts as a cleansing agent. Its antiseptic, softening  and soothing properties make this magical ingredient the perfect remedy for pimples.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- #3 -->
        <div v-if="id == 3">
            <div class="bg-cover bg-nature-drop-water">
                <div class="container mx-auto">
                    <div class="p-5 row">
                        <div class="col-12 flex flex-col items-start">
                            <h1 class="text-white text-3xl font-black font-epigrafica mt-2 uppercase ml-2">AVOCADO</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="container mx-auto">
                    <div class="py-14 bg-cover row">
                        <div class="container mx-auto">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                     <q-img fit="cover" class="img-smooth-zoom" ratio="1" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-avocat.jpg" />
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-4xl font-black font-epigrafica mt-2 uppercase">A FRUIT THAT IS GOOD FOR THE BODY AND ALSO EXCELLENT FOR THE EPIDERMIS AND THE  HAIR FIBER...</h1>
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-5">The regenerative power of the Avocado</h1>
                                    <p class="text-mineralgreen text-base">
                                        Delicious, the avocado is a good fruit for the body, but it is also excellent for the epidermis and the hair fiber. Composed of vitamins A, C and E, the avocado is also rich in nutrients.
                                        <br><br>
                                        These exceptional properties allow to protect the skin from external aggressions. But above all, it has a regenerating power. It helps healing and fights against the aging of the epidermis.
                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-2">The benefits of avocado oil</h1>
                                    <p class="text-mineralgreen text-base">
                                        For thousands of years, avocado has been used to heal wounds and chapped skin. Avocado vegetable oil is suitable for all skin types and can be easily integrated into a beauty routine.
                                        <br><br>
                                        Its oil moisturizes dry and fragile skin. With its anti-aging properties, avocado oil reduces puffiness and dark circles.
                                        On the hair, avocado oil also works miracles! It restores tone to dull hair, and nourishes damaged hair.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- #4 -->
        <div v-if="id == 4">
            <div class="bg-cover bg-nature-drop-water">
                <div class="container mx-auto">
                    <div class="p-5 row">
                        <div class="col-12 flex flex-col items-start">
                            <h1 class="text-white text-3xl font-black font-epigrafica mt-2 uppercase ml-2">ALOE VERA</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="container mx-auto">
                    <div class="py-14 bg-cover row">
                        <div class="container mx-auto">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                     <q-img fit="cover" class="img-smooth-zoom" ratio="1" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-aloe-vera.jpg" />
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-4xl font-black font-epigrafica mt-2 uppercase">IDEAL SOOTHING ACTIVE INGREDIENT FOR THE SKIN. IT IS A POWERFUL HEALING...</h1>
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-5">Aloe Vera gel is a powerful healing agent</h1>
                                    <p class="text-mineralgreen text-base">
                                        The gel contained in the leaves of the aloe vera plant contains glycoproteins, which greatly stimulate the skin's healing ability by soothing pain and inflammation. Other healing substances in aloe vera gel are polysaccharides, which boost skin regeneration and repair. According to some studies, the moisturizing and soothing properties of aloe vera gel make it ideal for eliminating dandruff and psoriasis, as well as other forms of dry or chapped skin. This gel can be applied in one layer to the scalp or face as a hydrating and relaxing mask.
                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-2">Aloe Vera gel deeply hydrates</h1>
                                    <p class="text-mineralgreen text-base">
                                        According to some studies, the moisturizing and soothing properties of aloe vera gel make it ideal for eliminating dandruff and psoriasis, as well as other forms of dry or chapped skin. This gel can be applied in one layer to the scalp or face as a hydrating and relaxing mask.
                                    </p>
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-2">Aloe Vera gel fights infections</h1>
                                    <p class="text-mineralgreen text-base">
                                        Aloe vera gel contains a natural antibacterial agent, which is why it is used in shaving creams or gels. In fact, it prevents ingrown hairs. Used alone or combined with a moisturizing agent, it is also an effective make-up remover, especially for people with sensitive skin.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- #5 -->
        <div v-if="id == 5">
            <div class="bg-cover bg-nature-drop-water">
                <div class="container mx-auto">
                    <div class="p-5 row">
                        <div class="col-12 flex flex-col items-start">
                            <h1 class="text-white text-3xl font-black font-epigrafica mt-2 uppercase ml-2">CURCUMA</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="container mx-auto">
                    <div class="py-14 bg-cover row">
                        <div class="container mx-auto">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                     <q-img fit="cover" class="img-smooth-zoom" ratio="1" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-curcuma.jpg" />
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-4xl font-black font-epigrafica mt-2 uppercase">IT HAS SEVERAL APPLICATIONS: ANTI-INFLAMMATORY, ANTI-AGING AND COLLAGEN BOOSTER...</h1>
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-5">Anti-inflammatory</h1>
                                    <p class="text-mineralgreen text-base">
                                        On the skin, inflammations can have several forms like acne, eczema and psoriasis. Highly anti-inflammatory, turmeric is an ideal ally to soothe these sensitive skins. It fights effectively against acne by regulating the production of sebum, it illuminates the skin and gives radiance to the face.
                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-2">Anti-aging</h1>
                                    <p class="text-mineralgreen text-base">
                                        Free radicals, pollution, UV rays and stress are aggravating factors of skin aging. Strong in antioxidants, turmeric helps to fight against this phenomenon.
                                    </p>
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-2">Boosts collagen</h1>
                                    <p class="text-mineralgreen text-base">
                                        Collagen is often mentioned when it comes to treating skin aging. Already present in the skin, its production decreases with time and the skin becomes less elastic. Turmeric helps to boost its production and its youthfulness.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- #6 -->
        <div v-if="id == 6">
            <div class="bg-cover bg-nature-drop-water">
                <div class="container mx-auto">
                    <div class="p-5 row">
                        <div class="col-12 flex flex-col items-start">
                            <h1 class="text-white text-3xl font-black font-epigrafica mt-2 uppercase ml-2">CUCUMBER</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="container mx-auto">
                    <div class="py-14 bg-cover row">
                        <div class="container mx-auto">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                     <q-img fit="cover" class="img-smooth-zoom" ratio="1" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-concombre.jpg" />
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-4xl font-black font-epigrafica mt-2 uppercase">THE CUCUMBER HAS MOISTURIZING, HEALING, SANITIZING AND ASTRINGENT PROPERTIES...</h1>
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-5">Its contribution in vitamins</h1>
                                    <p class="text-mineralgreen text-base">
                                        It provides a wide range of minerals and vitamins (more concentrated in its skin): potassium, all the vitamins of the B group, vitamin C (8 mg per 100 g), and some provitamin A and vitamin E.
                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-2">Unique virtues</h1>
                                    <p class="text-mineralgreen text-base">
                                        One allots to the juice of cucumber absorbed with jeun of the depuratives properties. Its richness in water (96%) gives it diuretic and draining virtues. Well provided in fibers, it takes part in the good functioning of the intestinal transit. The cucumber would be also a natural calming (anti-stress and anxiety).
                                    </p>
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-2">Beauty side</h1>
                                    <p class="text-mineralgreen text-base">
                                        Cucumber has moisturizing, healing, sanitizing and astringent properties. It is considered a softener, and is traditionally used (as a poultice or lotion) to fight redness, scabs, freckles, itching, dark circles and even wrinkles! It is also perfect for restoring the skin's radiance and   firming it, purifying the complexion and tightening the pores.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- #7 -->
        <div v-if="id == 7">
            <div class="bg-cover bg-nature-drop-water">
                <div class="container mx-auto">
                    <div class="p-5 row">
                        <div class="col-12 flex flex-col items-start">
                            <h1 class="text-white text-3xl font-black font-epigrafica mt-2 uppercase ml-2">COCONUT</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="container mx-auto">
                    <div class="py-14 bg-cover row">
                        <div class="container mx-auto">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                     <q-img fit="cover" class="img-smooth-zoom" ratio="1" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-coco.jpg" />
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-4xl font-black font-epigrafica mt-2 uppercase">KNOWN TO BE AN EXCELLENT HAIR CARE AND ALSO RICH IN FATTY ACIDS...</h1>
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-5">Hair absorbs coconut oil better than any other</h1>
                                    <p class="text-mineralgreen text-base">
                                        Coconut oil is known to be an excellent hair care product for a good reason: it is one of the most penetrating natural oils. A recent study found that within an hour, our hair could absorb up to the equivalent of 15% of its mass in coconut oil, and up to 20-25% within six hours. Pure coconut oil is nevertheless effective even in small doses.
                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-2">The power of Coconut oil</h1>
                                    <p class="text-mineralgreen text-base">
                                        It should be noted that by itself, coconut oil is rich in fatty acids, but low in vitamins. When combined with other beneficial actives such as vitamins and nutrients in a beauty product, coconut oil penetrates deeper into the scalp and hair fibers, making it an excellent carrier of  other substances. Pure coconut oil also helps to hold additional water-based moisturizers deeper into the skin and hair.
                                        <br><br>
                                        Thanks to its natural antibacterial properties, raw coconut oil can prevent the appearance of dandruff and fungal infections, ringworm, and other scalp infections and irritations.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- #8 -->
        <div v-if="id == 8">
            <div class="bg-cover bg-nature-drop-water">
                <div class="container mx-auto">
                    <div class="p-5 row">
                        <div class="col-12 flex flex-col items-start">
                            <h1 class="text-white text-3xl font-black font-epigrafica mt-2 uppercase ml-2">COCOA BUTTER</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="container mx-auto">
                    <div class="py-14 bg-cover row">
                        <div class="container mx-auto">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                     <q-img fit="cover" class="img-smooth-zoom" ratio="1" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-beurre-de-cacao.jpg" />
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-4xl font-black font-epigrafica mt-2 uppercase">IT IS MOISTURIZING, ANTIOXIDANT AND HEALING. IDEAL FOR THE HAIR...</h1>
                                    <p class="text-mineralgreen text-base">
                                        Certainly less known than the shea butter, the cocoa butter is not less provided with numerous virtues. It is moisturizing, antioxidant, healing ... The list of its properties is  extraordinarily long and deserves to be studied.
                                    </p>
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-5">Moisturizer</h1>
                                    <p class="text-mineralgreen text-base">
                                        Ideal for dry, damaged or dehydrated skin, cocoa butter is rich in stearic, palmitic and oleic acids which gives it an intense moisturizing action. Used on devitalized skin, cocoa butter soothes and nourishes. It can relieve certain irritations such as eczema.
                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-2">Antioxidant and Healing</h1>
                                    <p class="text-mineralgreen text-base">
                                        Cocoa butter is particularly nourishing because it is rich in fats, but also in flavonoids, hence its anti-oxidant action which gives it soothing and healing benefits. It is recommended for  pregnant women because it helps prevent stretch marks and cellulite formation.
                                    </p>
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-2">Ideal for hair</h1>
                                    <p class="text-mineralgreen text-base">
                                        Thanks to its intense moisturizing action, cocoa butter is also used in care for damaged and dry hair. It nourishes the hair while restoring its shine.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- #9 -->
        <div v-if="id == 9">
            <div class="bg-cover bg-nature-drop-water">
                <div class="container mx-auto">
                    <div class="p-5 row">
                        <div class="col-12 flex flex-col items-start">
                            <h1 class="text-white text-3xl font-black font-epigrafica mt-2 uppercase ml-2">SESAME OIL</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="container mx-auto">
                    <div class="py-14 bg-cover row">
                        <div class="container mx-auto">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                     <q-img fit="cover" class="img-smooth-zoom" ratio="1" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-huile-de-sesame.jpg" />
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-4xl font-black font-epigrafica mt-2 uppercase">SESAME OIL IS RICH IN VITAMIN E AND VITAMIN K1...</h1>
                                    <p class="text-mineralgreen text-base">
                                        Sesame oil is also full of monounsaturated fatty acids and polyunsaturated fatty acids. Its  nutrients are beneficial when used in cooking, but sesame oil also works wonders in cosmetics and in massages
                                    </p>
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-5">The benefits of SESAME OIL for the hair</h1>
                                    <p class="text-mineralgreen text-base">
                                        To make your hair shine without using chemicals, choose sesame oil. Its essential nutrients will protect the hair fiber and scalp, thus limiting the appearance of dandruff and hair loss. For children, it is a formidable anti-lice weapon.

                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-2">The benefits of SESAME OIL for the skin</h1>
                                    <p class="text-mineralgreen text-base">
                                        Rich in essential fatty acids, sesame oil regenerates and softens the skin. Vitamin E, lecithin and sesamoline are all natural antioxidants that are part of the composition of sesame oil and will therefore protect the skin from aging, especially in case of sun exposure. Regenerating, softening and moisturizing, sesame oil is particularly suitable for irritated or dry skin. It can be applied alone or mixed with another vegetable oil. It can also be used as an anti-aging treatment on the face.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <hr>

        <!-- MORE -->
        <div class="white">
            <div class="container mx-auto">
                <div class="py-14 bg-cover row">
                    <div class="container mx-auto">
                        <div class="row">

                            <div class="col-12">
                                <h1 class="text-mineralgreen text-center text-2xl font-black font-epigrafica mt-2 uppercase">Discover more ingredients</h1>
                            </div>

                            <div class="col-12 col-sm-12 col-md-4 p-5 img-smooth-zoom">
                                <div class="relative">
                                    <div class="relative mb-20">
                                        <div class="absolute z-40 bg-white m-10 p-4 -bottom-24 border-solid border-pastelgolden border-b-2">
                                            <h1 class="text-mineralgreen text-lg font-black font-epigrafica uppercase">ALOE VERA</h1>
                                            <p class="text-mineralgreen mb-2">Ideal soothing active ingredient for the skin. It is a powerful healing...</p>
                                            <a href="/page/en/ingredient/4/aloe-vera" class="text-mineralgreen-dark hover:text-mineralgreen font-bold">Discover &rarr;</a>
                                        </div>
                                        <q-img width="100%" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-aloe-vera.jpg" />
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-4 p-5 img-smooth-zoom">
                                <div class="relative">
                                    <div class="relative mb-20">
                                        <div class="absolute z-40 bg-white m-10 p-4 -bottom-24 border-solid border-pastelgolden border-b-2">
                                            <h1 class="text-mineralgreen text-lg font-black font-epigrafica uppercase">CURCUMA</h1>
                                            <p class="text-mineralgreen mb-2">It has several applications: anti-inflammatory, anti-aging and collagen booster...</p>
                                            <a href="/page/en/ingredient/5/curcuma" class="text-mineralgreen-dark hover:text-mineralgreen font-bold">Discover &rarr;</a>
                                        </div>
                                        <q-img width="100%" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-curcuma.jpg" />
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-12 col-sm-12 col-md-4 p-5 img-smooth-zoom">
                                <div class="relative">
                                    <div class="relative mb-20">
                                        <div class="absolute z-40 bg-white m-10 p-4 -bottom-24 border-solid border-pastelgolden border-b-2">
                                            <h1 class="text-mineralgreen text-lg font-black font-epigrafica uppercase">CUCUMBER</h1>
                                            <p class="text-mineralgreen mb-2">The cucumber has moisturizing, healing, sanitizing and astringent properties...</p>
                                            <a href="/page/en/ingredient/6/concombre" class="text-mineralgreen-dark hover:text-mineralgreen font-bold">Discover &rarr;</a>
                                        </div>
                                        <q-img width="100%" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-concombre.jpg" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- FOOTER CONTENT -->
        <!-- footer -->
        <footerbar></footerbar>
        
    </q-layout>
</template>

<script>
import navbar from "@/views/shared/en/Navbar"
import footerbar from "@/views/shared/en/Footer"
import { useMeta } from "quasar"

export default {
    name: "Ingredient",
    components: { navbar, footerbar },
    data() {
        return {}
    },
    computed: {
        id () { return this.$route.params.id },
    },
    created () {
        useMeta({
            title: 'International Bio Cosmetics | Ingrédient', // sets document title
        })
    }
};
</script>